<template>
  <div class="order">
    <Title :service-order="true" @queryOrder="getOrderList" />
    <div class="List">
      <div class="Tabs">
        <div class="Tabs-item">全部</div>
      </div>
      <List
        :List="OrderList"
        @ToPay="ToPay"
        :State="false"
        @changePrice="changePrice"
        @GetDetails="GetDetails"
        @GetWork="GetWork"
        @GetEvaluate="GetEvaluate"
      />
      <!-- 暂无信息 -->
      <!-- <el-empty v-show="OrderListShow" :image="NoList"></el-empty> -->
      <div style="margin-top: 100px">
        <el-empty
          v-show="showEmpty"
          :image-size="80"
          :image="NoList"
        ></el-empty>
      </div>
      <Pagination :total="total" :size="queryParam.pageSize" :page="queryParam.pageNo" @handlePage="PageChange" v-if="total > queryParam.pageSize" />
      <!-- 支付弹框 -->
      <!-- <Open/> -->
      <!-- 取消订单 -->
      <!-- <Cancel /> -->
      <!-- 开始工作 -->
      <!-- 结束工作 -->
      <Work
        ref="Work"
        :title="Worktitle"
        :WorkDesc="WorkDesc"
        @handleWork="handleWork"
      />
      <!-- 评价 -->
      <Evaluate ref="Evaluate" @List="getOrderList" />
    </div>
  </div>
</template>
<script>
import Title from "@/components/OrderTab/Title.vue";
import List from "@/components/OrderTab/Order1.vue";
// import List from "@/components/OrderTab/Order1.vue";
// import Open from "@/views/Order/Open.vue";
// import Cancel from "@/views/Order/Cancel.vue";
import Work from "@/views/Order/Work.vue";
import Evaluate from "@/views/Order/Evaluate.vue";
import Pagination from "@/components/Pagination/index.vue";
import moment from "moment";
import { SERVICE_ORDER_STATE } from "@/constants/order";
export default {
  data() {
    return {
      OrderList: [],
      showEmpty: false,
      OrderId: null,
      Worktitle: null,
      WorkDesc: null,
      NoList: require("@/assets/imgs/no_hall.png"),

      total: 0,

      queryParam: {
        userId: this.$store.getters.getUser.id,
        pageNo: 1,
        pageSize: 10,
      }
    };
  },
  components: {
    Pagination,
    Title,
    List,
    // Open,
    // Cancel,
    Work,
    Evaluate,
  },
  created() {
    this.getOrderList();
  },
  methods: {
    // 我的订单
    getOrderList(param) {
      let url = `/app-api/hiring/service-order/page`;
      if (param && param.state) {
        this.queryParam.orderState = param.state;
      } else {
        delete this.queryParam.orderState
      }
      if (param && param.date && param.date.length > 1) {
        this.queryParam.createTime = [moment(param.date[0]).format('YYYY-MM-DD HH:mm:ss'), moment(param.date[1]).format('YYYY-MM-DD HH:mm:ss')].join(',');
      } else {
        delete this.queryParam.createTime
      }
      // console.log(this.queryParam)

      this.$axios
          .get(url, {params: this.queryParam})
          .then((res) => {
            if (res.data.code === 0) {
              this.OrderList = res.data.data.list;
              this.total = res.data.data.total
              this.showEmpty = !this.OrderList;
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    PageChange(val) {
      this.queryParam.pageNo = val;
      this.getOrderList(this.queryParam);
    },
    // 支付订单
    ToPay(item) {
      // 价格判断
      let price;
      if (item.serviceAmountType == "hour") {
        price = item.serviceAmountHour;
      } else if (item.serviceAmountType == "day") {
        price = item.serviceAmountDay;
      } else if (item.serviceAmountType == "week") {
        price = item.serviceAmountWeek;
      } else {
        price = item.serviceAmountMonth;
      }
      // 倒计时

      // 跳转到支付页面
      this.$router.push({
        name: "Buy",
        params: { id: item.id, title: item.service_title, price },
      });
      // 现阶段 弹框
    },
    // 点击 列表 进入 订单详情页面
    GetDetails(item) {
      this.$router.push({
        path: "/Order/Provider/Detail",
        query: { id: item.id },
      });
    },
    changePrice(item) {
      this.$prompt('请输入价格', '修改价格', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^(\d+)((?:\.\d+)?)$/,
        inputErrorMessage: '请输入大于零的有效数字',
        closeOnClickModal: false
      }).then(({ value }) => {
        this.doChangePrice(item, value)
      }).catch(() => {
      });
      
    },
    doChangePrice(item, value) {
      let url = `/app-api/hiring/service-order/update-price`;
      this.$axios
          .post(url, {
            id: item.id,
            price: value
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.$notify.success("改价成功！");
              this.getOrderList();
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    // 开始工作 start
    GetWork(item) {
      // 弹框提示
      if (item.orderState === SERVICE_ORDER_STATE.WAITING_WORKING.value) {
        this.Worktitle = "是否开始工作";
        this.WorkDesc = "开始工作";
      } else if (item.orderState === SERVICE_ORDER_STATE.WORKING.value) {
        this.Worktitle = "是否结束工作";
        this.WorkDesc = "结束工作";
      }
      this.$refs.Work.Open = true;
      this.OrderId = item;
    },
    handleWork() {
      if (this.OrderId.orderState === SERVICE_ORDER_STATE.WAITING_WORKING.value) {
        this.PostWork();
      } else if (this.OrderId.orderState === SERVICE_ORDER_STATE.WORKING.value) {
        this.PostEndWork();
      }
    },
    PostWork() {
      let url = "/app-api/hiring/service-order/start-work";
      this.$axios
        .post(url, {
          id: this.OrderId.id
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.$modal.notify("操作成功！");
            this.$refs.Work.Open = false;
            this.getOrderList();
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // end
    PostEndWork() {
      let url = "/app-api/hiring/service-order/complete-work";
      this.$axios
        .post(url, {
          id: this.OrderId.id
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.$modal.notify("操作成功！");
            this.$refs.Work.Open = false;
            this.getOrderList();
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // 去评价
    GetEvaluate(item) {
      this.OrderId = item;
      this.$refs.Evaluate.open = true;
      item.avter = this.service_label(item.serviceImg)[0];
      this.$refs.Evaluate.order = item;
    },
  },
};
</script>
<style lang="less" scoped>
.List {
  width: 100%;
  .Tabs {
    display: flex;
    margin-top: 40px;
    .Tabs-item {
      font-weight: 400;
      color: #000000;
      line-height: 23px;
      font-size: 20px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        width: 80%;
        height: 2px;
        background: #000000;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
